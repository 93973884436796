import AdminLayout from "../../layout/admin";
import {deleteContentApi, getContentsApi, postContentApi} from "../../api/api";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Input, message, Modal, Table} from "antd";
import Moment from "react-moment";
import 'moment/locale/fa';
import {Link, useNavigate} from "react-router-dom";
import {Domain} from "../../api/config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {editorConfiguration} from "../../api/common";

const Contents = () => {

    const navigate = useNavigate();
    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [newContent, setNewContent] = useState({});

    useEffect(()=>{
        getContents(1)
    },[])

    const getContents = async (p) => {
        setLoading(true)
        const data = {
            page: p
        }
        await getContentsApi(data, token).then((r)=>{
            setContents(r.data)
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'نامک',
            dataIndex: 'slug',
            key: 'slug',
            render: (slug)=> <Link to={`${Domain}/content/${slug}`}>{slug}</Link>
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment element={'time'} locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at) => <Moment element={'time'} locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_,_value) => <>
                <Button type="primary" onClick={()=>navigate(`/content/edit/${_value.id}`)}>ویرایش</Button>
                <Button style={{marginRight: "10px"}} danger onClick={()=>handleRemove(_value.id)}>حذف</Button>
            </>
        }
    ]

    const handleNewContent = async () => {
        let data = {...newContent};
        data.description = JSON.stringify(data.description)

        await postContentApi(data, token).then(()=>{
            message.success("با موفقیت ایجاد شد")
            setModal(false)
            setNewContent({})
            getContents(1);
        })
    }

    const handleRemove = async (id) => {
        await deleteContentApi(id, token).then(()=>{
            message.success("با موفقیت حذف شد")
            getContents(1);
        })
    }


    return (
        <AdminLayout>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h1>محتوا</h1>
                <Button type="primary" onClick={()=>setModal(true)}>جدید</Button>
            </div>
            <Table
                dataSource={contents?.data}
                columns={columns}
            />

            <Modal open={modal} onCancel={()=>setModal(false)} onOk={()=>handleNewContent()}>
                <div style={{padding: "5px"}}>
                    <Input value={newContent?.title} onChange={(e)=>setNewContent({...newContent, title: e.target.value})}/>
                </div>
                <div style={{padding: "5px"}}>
                    <Input value={newContent?.slug} onChange={(e)=>setNewContent({...newContent, slug: e.target.value})}/>
                </div>
                <div style={{padding: "5px"}}>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={newContent?.description}
                        config={editorConfiguration}
                        onChange={ ( event, editor ) => {
                            setNewContent({...newContent, description: editor.getData()})
                        }}
                    />
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default Contents;