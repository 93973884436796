import AdminLayout from "../../layout/admin";
import {useParams} from "react-router-dom";
import {
    getAdsIdApi,
    getProvinceApi,
    getCategoryApi,
    getSubCategoryApi,
    getSubSubCategoryApi,
    getDistrictByCityApi,
    getCityByProvinceApi, updateAdsApi, getBrandsApi, getModelsApi, getTagsApi, postMessageApi
} from "../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Input, Card, Image, Row, Col, Select, notification, Dropdown, Button, Modal} from "antd";
import {Root} from "../../api/config";
import {CheckOutlined, CloseOutlined, DeleteOutlined} from "@ant-design/icons";

const EditAds = () => {

    const { ads_id } = useParams();
    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [ads, setAds] = useState({});

    const [province, setProvince] = useState([]);
    const [city, setCity] = useState([]);
    const [district, setDistrict] = useState([]);

    const [category, setCategory] = useState([]);
    const [SubCategory, setSubCategory] = useState([]);
    const [SubSubCategory, setSubSubCategory] = useState([]);
    const [Brands, setBrands] = useState([]);
    const [Models, setModels] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [adsId, setAdsId] = useState(null);
    const [update, setUpdate] = useState({});
    const [buttonLoad, setButtonLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState({});

    useEffect(()=> {
        if(ads_id){
            getAdsId(ads_id);
            getProvince();
            getCategory();
            getTags();
        }
    },[]);

    useEffect(()=>{
        if(tags.length && ads){
            getTag(ads.tags);
        }
    },[tags])

    useEffect(()=>{
        if(adsId){
            updateAds()
        }
    },[update]);

    const getTag = (data) => {
        let options = data?.split(",").map(Number);
        if(tags.length){
            let findOption = tags?.filter(option => options?.includes(option?.id));
            setSelectedTags(findOption);
        }
    }

    const getAdsId = async (id) => {
        await getAdsIdApi(id, token).then((r)=> {
            const {updated_at, created_at, ...clone} = r.data;
            setAds(clone)
            getSubCategory(r.data.cat)
            getSubSubCategory(r.data.subcat)
            getCity(r.data.province)
            getDistrict(r.data.city)
            getBrand(r.data.subsubcat)
            getModel(r.data.brand)
            setMessage({
                ads_id: ads_id,
                user_id: r.data?.user
            })
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getProvince = async() => {
        await getProvinceApi().then((p)=>{
            p.data.forEach(item => {item.value = item.id; item.label = item.title});
            setProvince(p.data);
        });
    }

    const getCity = async(id) => {
        await getCityByProvinceApi(id).then((c)=>{
            c.data.forEach(item => {item.value = item.id; item.label = item.title});
            setCity(c.data);
        });
    }
    const getDistrict = async(id) => {
        await getDistrictByCityApi(id).then((d)=>{

            const data = d.data;
            const result = data.reduce((accumulator, item) => {
                item.value = item.id;
                item.label = item.title;

                if (item.dist) {
                    const obj = {
                        label: item.title,
                        options: data.filter(x => !x.dist && x.dis === item.dist)
                    };
                    accumulator.push(obj);
                }

                return accumulator;
            }, []);

            result.length ? setDistrict(result) : setDistrict(data);
        });
    }

    const getCategory = async() => {
        await getCategoryApi().then((p)=>{
            p.data.filter(item => (item.value = item.id, item.label = item.name));
            setCategory(p.data);
        });
    }
    const getSubCategory = async(id) => {
        await getSubCategoryApi(id).then((p)=>{
            p.data.filter(item => (item.value = item.id, item.label = item.name));
            setSubCategory(p.data);
        });
    }
    const getSubSubCategory = async(id) => {
        await getSubSubCategoryApi(id).then((p)=>{
            p.data.filter(item => (item.value = item.id, item.label = item.name));
            setSubSubCategory(p.data);
        });
    }

    const getBrand = async(id) => {
        await getBrandsApi(id).then((p)=>{
            p.data.filter(item => (item.value = item.id, item.label = item.name));
            setBrands(p.data);
        });
    }

    const getModel = async(id) => {
        await getModelsApi(id).then((p)=>{
            p.data.filter(item => (item.value = item.id, item.label = item.name));
            setModels(p.data);
        });
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpdateAds = async () => {
        setButtonLoad(true)
        await updateAdsApi(ads, ads_id, token).then(()=>{
            notification.success({
                message: "موفق",
                description: "عملیات با موفقیت انجام شد"

            })
            setButtonLoad(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getTags = async (p) => {

        await getTagsApi(null, token).then((r)=>{
            r.data?.filter((x)=> (x.label = x.title, x.value = x.id))
            setTags(r.data)
        })
    }

    const handleTags = (c) => {
        setAds({...ads, tags: c.toString()})
        getTag(c.toString())
    }

    const updateAds = async () => {
        await updateAdsApi(update, ads_id, token).then(()=>{
            notification.success({
                message: "موفق",
                description: "عملیات با موفقیت انجام شد"

            })
            setButtonLoad(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const handleChangeStatus = (key, id) => {
        setButtonLoad(true)
        setAdsId(id);

        if(key == 1){
            setUpdate({approved: 1})

            const msg = {
                subject: `آگهی ${ads?.title} تائید شد!`,
                message: `کاربر گرامی نوبازار، آگهی شما با عنوان ${ads?.title} منتشر شد.`,
                type: 3,
                ads_id: ads_id,
                user_id: ads?.user
            }
            handleSendMessage(msg)

        } else if (key == 2) {
            setUpdate({approved: 3})
        } else if (key == 3) {
            setUpdate({deleted: 1})
        }
    }

    const items = [
        {
            label: 'تائید',
            key: '1',
            value: 1,
            icon: <CheckOutlined />
        },
        {
            label: 'عدم تائید',
            key: '2',
            value: 2,
            icon: <CloseOutlined />
        },
        {
            label: 'حذف',
            key: '3',
            value: 3,
            icon: <DeleteOutlined />,
            danger: true,
        }
    ];

    const handleSendMessage = async (m) => {
        await postMessageApi(m, token).then(()=>{
            setModal(false)
            notification.success({
                message: "موفق",
                description: "عملیات با موفقیت انجام شد"
            })
        })
    }

    return (
        <AdminLayout>
            <h1>آگهی {ads_id}</h1>

            <Row style={{marginBottom: "10px"}}>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.province || null}
                        style={{width: "100%"}}
                        placeholder={'استان'}
                        loading={!province.length}
                        disabled={!province.length}
                        showSearch
                        filterOption={filterOption}
                        options={province.length ? province : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> (setAds({...ads, province: choice}), getCity(choice))}
                    />
                </Col>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.city || null}
                        style={{width: "100%"}}
                        placeholder={'شهر'}
                        loading={!city.length}
                        disabled={!ads?.city || !city.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={city.length ? city : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> (setAds({...ads, city: choice}), getDistrict(choice))}
                    />
                </Col>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.district || null}
                        style={{width: "100%"}}
                        placeholder={'منطقه'}
                        loading={!district.length}
                        disabled={!ads?.district || !district.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={district.length ? district : [{label: 'همه', value: ''}]}
                        onChange={(choice)=>setAds({...ads, district: choice})}
                    />
                </Col>
            </Row>

            <Row style={{marginBottom: "10px"}}>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.cat || null}
                        style={{width: "100%"}}
                        placeholder={'دسته بندی'}
                        loading={!category.length}
                        disabled={!category.length}
                        showSearch
                        filterOption={filterOption}
                        options={category.length ? category : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> (setAds({...ads, cat: choice}), getSubCategory(choice))}
                    />
                </Col>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.subcat || null}
                        style={{width: "100%"}}
                        placeholder={'زیر دسته'}
                        loading={!SubCategory.length}
                        disabled={!ads?.subcat || !SubCategory.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={SubCategory.length ? SubCategory : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> (setAds({...ads, subcat: choice}), getSubSubCategory(choice))}
                    />
                </Col>
                <Col md={8} style={{padding: "5px"}}>
                    <Select
                        value={ads?.subsubcat || null}
                        style={{width: "100%"}}
                        placeholder={'زیر دسته'}
                        loading={!SubSubCategory.length}
                        disabled={!SubSubCategory.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={SubSubCategory.length ? SubSubCategory : [{label: 'همه', value: ''}]}
                        onChange={(choice)=>setAds({...ads, subsubcat: choice})}
                    />
                </Col>
            </Row>

            <Row style={{marginBottom: "20px"}}>
                <Col md={12} style={{padding: "5px"}}>
                    <Select
                        value={ads?.brand || null}
                        style={{width: "100%"}}
                        placeholder={'برند'}
                        loading={!Brands.length}
                        disabled={!Brands.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={Brands.length ? Brands : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> (setAds({...ads, brand: choice}), getModel(choice))}
                    />
                </Col>
                <Col md={12} style={{padding: "5px"}}>
                    <Select
                        value={ads?.model || null}
                        style={{width: "100%"}}
                        placeholder={'مدل'}
                        loading={!Models.length}
                        disabled={!Models.length ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={Models.length ? Models : [{label: 'همه', value: ''}]}
                        onChange={(choice)=>setAds({...ads, model: choice})}
                    />
                </Col>
            </Row>

            <div style={{marginBottom: "10px"}}>
                <Input value={ads?.title} onChange={(e)=>setAds({...ads, title: e.target.value})}/>
            </div>

            <div>
                <Input.TextArea style={{height: "200px"}} value={ads?.description} onChange={(e)=>setAds({...ads, description: e.target.value})}/>
            </div>

            <Row style={{marginTop: "50px"}}>
                {ads?.img1 ? <Col md={4} style={{margin: "5px"}}>
                    <Card cover={<Image src={`${Root}/seller/uploads/${ads?.user}/${ads?.adsid}/${ads?.img1}-resized.jpg`}/>}>

                        <Input value={ads?.img1}/>
                    </Card>
                </Col> : null}
                {ads?.img2 ? <Col md={4} style={{margin: "5px"}}>
                    <Card cover={<Image src={`${Root}/seller/uploads/${ads?.user}/${ads?.adsid}/${ads?.img2}-resized.jpg`}/>}>

                        <Input value={ads?.img2}/>
                    </Card>
                </Col> : null}
                {ads?.img3 ? <Col md={4} style={{margin: "5px"}}>
                    <Card cover={<Image src={`${Root}/seller/uploads/${ads?.user}/${ads?.adsid}/${ads?.img3}-resized.jpg`}/>}>

                        <Input value={ads?.img3}/>
                    </Card>
                </Col> : null}
                {ads?.img4 ? <Col md={4} style={{margin: "5px"}}>
                    <Card cover={<Image src={`${Root}/seller/uploads/${ads?.user}/${ads?.adsid}/${ads?.img4}-resized.jpg`}/>}>

                        <Input value={ads?.img4}/>
                    </Card>
                </Col> : null}
                {ads?.img5 ? <Col md={4} style={{margin: "5px"}}>
                    <Card cover={<Image src={`${Root}/seller/uploads/${ads?.user}/${ads?.adsid}/${ads?.img5}-resized.jpg`}/>}>

                        <Input value={ads?.img5}/>
                    </Card>
                </Col> : null}
            </Row>

            {ads.vid ?
                <div style={{marginTop: "10px"}}>
                    <video controls >
                        <source src={`${Root}/videos/${ads.user}/${ads.adsid}.mp4`} type="video/mp4"/>
                    </video>
                </div>
                : null
            }

            <Row>
                <Col md={12}>
                    <Input value={ads?.price} placeholder={"قیمت"}/>
                </Col>

                <Col md={12}>
                    <Select
                        value={selectedTags}
                        mode="multiple"
                        style={{width: "100%"}}
                        placeholder={'برچسب ها'}
                        loading={!tags.length}
                        disabled={!tags ? true : false}
                        showSearch
                        filterOption={filterOption}
                        options={tags.length ? tags : [{label: 'همه', value: ''}]}
                        onChange={(choice)=> handleTags(choice)}
                    />
                </Col>
            </Row>

            <div style={{display: "flex", marginTop: "30px"}}>
                <Dropdown.Button
                    type={"primary"}
                    disabled={buttonLoad}
                    loading={buttonLoad}
                    menu={{items, onClick: ({key})=> handleChangeStatus(key, ads_id)}}
                    onClick={()=>handleUpdateAds()}
                >
                    ذخیره
                </Dropdown.Button>
                <Button onClick={()=>setModal(true)}>ارسال پیام برای کاربر</Button>
            </div>

            <Modal open={modal} onCancel={()=>setModal(false)} onOk={()=> handleSendMessage(message)}>
                <div style={{padding: "5px"}}>
                    <Input onChange={(e)=>setMessage({...message, subject: e.target.value})}/>
                </div>
                <div style={{padding: "5px"}}>
                    <Select
                        value={message?.type}
                        style={{width: "100%"}}
                        placeholder={'نوع پیام'}
                        options={[{label: 'اصلاحیه', value: 1}, {label: 'اخطاریه', value: 2}, {label: 'اعلامیه', value: 3}]}
                        onChange={(choice)=> setMessage({...message, type: choice})}
                    />
                </div>
                <div style={{padding: "5px"}}>
                    <Input.TextArea onChange={(e)=>setMessage({...message, message: e.target.value})}/>
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default EditAds;