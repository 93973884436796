import AdminLayout from "../../layout/admin";
import {
    getBrandsApi,
    getCategoryApi,
    getModelsApi,
    getSubCategoryApi,
    getSubSubCategoryApi, postBrandApi,
    postCategoryApi,
    postSubSubCategoryApi,
    postSubCategoryApi,
    postModelApi
} from "../../api/api";
import {useEffect, useState} from "react";
import {Collapse, Button, Tag, Modal, Input, message} from 'antd';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
const {Panel} = Collapse;

const Category = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);

    const [activeCat, setActiveCat] = useState(null);
    const [activeSubCat, setActiveSubCat] = useState(null);
    const [activeSubSubCat, setActiveSubSubCat] = useState(null);
    const [activeBrand, setActiveBrand] = useState(null);
    const [modal, setModal] = useState(false);
    const [which, setWhich] = useState(null);
    const [parent, setParent] = useState(null);
    const [formInput, setFormInput] = useState({});

    useEffect(()=>{
        getCategory()
    },[])

    const getCategory = async () => {
        await getCategoryApi().then((res)=>{
            setCategories(res.data)
        })
    }

    const getSubCategory = async (id) => {
        await getSubCategoryApi(id).then((res)=>{
            setSubCategories(res.data)
        })
    }

    const getSubSubCategory = async (id) => {
        await getSubSubCategoryApi(id).then((res)=>{
            setSubSubCategories(res.data)
        })
    }

    const getBrands = async (id) => {
        await getBrandsApi(id).then((res)=>{
            setBrands(res.data)
        })
    }

    const getModels = async (id) => {
        await getModelsApi(id).then((res)=>{
            setModels(res.data)
        })
    }

    const handleCategory = (id) => {
        let idNumber = Number(id);
        setActiveCat(id)
        if(idNumber){
            getSubCategory(idNumber)
        }
    }

    const handleSubCategory = (id) => {
        let idNumber = Number(id);
        setActiveSubCat(id)
        if(idNumber){
            getSubSubCategory(idNumber)
        }
    }

    const handleSubSubCategory = (id) => {
        let idNumber = Number(id);
        setActiveSubSubCat(id)
        if(idNumber){
            getBrands(idNumber)
        }
    }

    const handleBrands = (id) => {
        let idNumber = Number(id);
        setActiveBrand(id)
        if(idNumber){
            getModels(idNumber)
        }
    }

    const extraCollapse = (w, p) => {
        return (
            <>
                <Button onClick={()=>handleModal(w, p)}><PlusOutlined /></Button>
                <Button type={"primary"}><EditOutlined /></Button>
            </>
        )
    }

    const handleNew = () => {
        if(which === "cat"){
            postNewCategory()
        } else if(which === "subcat"){
            postNewSubCategory()
        } else if(which === "subsubcat"){
            postNewSubSubCategory()
        } else if(which === "brand"){
            postNewBrand()
        } else if(which === "model"){
            postNewModel()
        }
    }

    const postNewCategory = async () => {
        await postCategoryApi(formInput, token).then(()=>{
            getCategory();
            setModal(false)
            message.success("با موفقیت ایجاد شد")
            setFormInput({})
        })
    }

    const postNewSubCategory = async () => {
        let data = {...formInput};
        data.cat_id = parent;

        await postSubCategoryApi(data, token).then(()=>{
            getSubCategory(parent);
            setModal(false)
            message.success("با موفقیت ایجاد شد")
            setFormInput({})
        })
    }

    const postNewSubSubCategory = async () => {
        let data = {...formInput};
        data.subcat_id = parent;

        await postSubSubCategoryApi(data, token).then(()=>{
            getBrands(parent);
            setModal(false)
            message.success("با موفقیت ایجاد شد")
            setFormInput({})
        })
    }

    const postNewBrand = async () => {
        let data = {...formInput};
        data.sscat_id = parent;

        await postBrandApi(data, token).then(()=>{
            getModels(parent);
            setModal(false)
            message.success("با موفقیت ایجاد شد")
            setFormInput({})
        })
    }

    const postNewModel = async () => {
        let data = {...formInput};
        data.brand_id = parent;

        await postModelApi(data, token).then(()=>{
            setModal(false)
            message.success("با موفقیت ایجاد شد")
            setFormInput({})
        })
    }

    const handleModal = (w, p) => {
        setModal(true)
        setWhich(w)
        if(p){
            setParent(p)
        }
    }

    return (
        <AdminLayout>
            <Button style={{marginBottom: "15px"}} onClick={()=>handleModal('cat')}>+</Button>
            <Collapse accordion onChange={handleCategory} activeKey={activeCat}>
                {categories?.map((Category)=>{
                    return (
                        <Panel header={Category.name} key={Category.id} extra={extraCollapse('subcat', Category.id)}>
                            {subCategories?.map((SubCat)=>{
                                return (
                                    <Collapse key={SubCat.id} accordion onChange={handleSubCategory} activeKey={activeSubCat}>
                                        <Panel key={SubCat.id} header={SubCat.name} extra={extraCollapse('subsubcat', SubCat.id)}>
                                            {subSubCategories?.map((SubSubCat)=>{
                                                return (
                                                    <Collapse key={SubSubCat.id} accordion onChange={handleSubSubCategory} activeKey={activeSubSubCat}>
                                                        <Panel key={SubSubCat.id} header={SubSubCat.name} extra={extraCollapse('brand', SubSubCat.id)}>
                                                            {brands?.map((Brand)=>{
                                                                return (
                                                                    <Collapse key={Brand.id} accordion onChange={handleBrands} activeKey={activeBrand}>
                                                                        <Panel key={Brand.id} header={Brand.name} extra={extraCollapse('model', Brand.id)}>
                                                                            {models?.map((Model)=>{
                                                                                return (
                                                                                    <Tag key={Model.id}>
                                                                                        {Model.name}
                                                                                    </Tag >
                                                                                )
                                                                            })}
                                                                        </Panel>
                                                                    </Collapse>
                                                                )
                                                            })}
                                                        </Panel>
                                                    </Collapse>
                                                )
                                            })}
                                        </Panel>
                                    </Collapse>
                                )
                            })}
                        </Panel>
                    )
                })}
            </Collapse>

            <Modal
                open={modal}
                title={"دسته بندی جدید"}
                onCancel={()=>setModal(false)}
                onOk={()=> handleNew()}
            >
                <div>
                    <label>نام</label>
                    <Input value={formInput.name} onChange={(e)=> setFormInput({...formInput, name: e.target.value})}/>
                </div>
                <div>
                    <label>صف</label>
                    <Input value={formInput.queue} onChange={(e)=> setFormInput({...formInput, queue: e.target.value})}/>
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default Category;