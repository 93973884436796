import AdminLayout from "../../layout/admin";
import {createTicketMessageApi, getTicketByIdApi, getTicketMessageApi, putTicketMessageApi} from "../../api/api";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Alert, Avatar, Button, message} from "antd";
import Moment from "react-moment";
import 'moment/locale/fa';
import {Domain} from "../../api/config";
import TextArea from "antd/es/input/TextArea";

const Ticket = () => {

    const state = useSelector((state)=>state);
    const user = state.auth.user;
    const token = state.auth.token;
    const { ticket_id } = useParams();
    const [Ticket, setTicket] = useState({});
    const [Messages, setMessages] = useState([]);
    const [newTicket, setNewTicket] = useState(
        {
            user_id: user.id,
            ticket_id: ticket_id
        }
    );

    useEffect(()=>{
        getTicket()
        if(ticket_id){
            getTicketMessage(ticket_id)
        }
    },[])

    const getTicket = async () => {
        await getTicketByIdApi(ticket_id, token).then((r)=>{
            setTicket(r.data)
        })
    }

    const createTicketMessage = async () => {
        await createTicketMessageApi(newTicket, token).then(()=>{
            message.success("Sent Successfully!")
            getTicketMessage()
            putTicketMessage()
        }).catch(()=>{

        })
    }

    const getTicketMessage = async (id) => {
        await getTicketMessageApi(id, token).then((result)=>{
            setMessages(result.data)
        }).catch(()=>{

        })
    }

    const putTicketMessage = async () => {
        const data = {
            status: 2
        }
        await putTicketMessageApi(ticket_id, data, token).then(()=>{

        }).catch(()=>{

        })
    }

    return (
        <AdminLayout>
            <Alert className={"d-flex align-items-center mb-2 p-4"}
                   message={[
                       <div key={1} className={"d-flex align-items-center"}>
                           <Avatar size={"large"} key={1} src={`${Domain}/assets/img/avatar/${Ticket?.seller?.pic}.png`}/>
                           <div className={"me-3"}>
                               <div key={2} className={"d-flex"}>
                                                   <span className={"font-size-12 mb-2 d-block"}>
                                                       <span className={"font-size-15"}><i className="las la-user"></i></span>
                                                       <span className={"me-2"}>{Ticket?.seller?.name} {Ticket?.seller?.lastname}</span>
                                                   </span>
                                   <span className={"font-size-12 mx-2"}>|</span>
                                   <span className={"font-size-15"}><i className="las la-clock"></i></span>
                                   <Moment className={"font-size-12 me-2"} element={'time'} locale={'fa'} fromNow>{Ticket.created_at}</Moment>
                               </div>

                               <p className={"p-0 m-0 font-size-14"}>{Ticket?.description}</p>
                           </div>
                       </div>
                   ]}>
            </Alert>

            <div style={{marginTop: "10px"}}>
                {Messages.map((Message)=>{
                    const Admin = Message.user_id === 91;
                    return (
                        <Alert type={Admin ? "error" : "info"} className={"d-flex align-items-center"} style={{marginBottom: "10px"}} message={[
                            <div key={1}>
                                <div className={"me-3"}>
                                    <div key={2} className={"d-flex"}>
                                                   <span className={"font-size-12 mb-2 d-block"}>
                                                   </span>
                                        <Moment className={"font-size-12 me-2"} element={'time'} locale={'fa'} fromNow>{Message.created_at}</Moment>
                                    </div>

                                    <p className={"p-0 m-0 font-size-14"}>{Message?.description}</p>
                                </div>
                            </div>
                        ]}>
                        </Alert>
                    )
                })}
            </div>

            <div style={{marginTop: "20px"}}>
                <TextArea onChange={(e)=>setNewTicket({...newTicket, description: e.target.value})} style={{height: "200px"}}/>
                <Button onClick={()=>createTicketMessage()} style={{marginTop: "10px"}} type="primary">ارسال</Button>
            </div>
        </AdminLayout>
    )
}

export default Ticket;