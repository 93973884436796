import AdminLayout from "../../layout/admin";
import {getReportsApi} from "../../api/api";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Table, Tag} from "antd";
import Moment from "react-moment";
import 'moment/locale/fa';
import {Link} from "react-router-dom";
import {Domain} from "../../api/config";
import Scripts from "../../api/scripts";

const Reports = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModel] = useState(false);

    useEffect(()=>{
        getReports(1);
    },[]);

    const getReports = async (p) => {
        setLoading(true)
        const data = {
            page: p
        }
        await getReportsApi(data, token).then((r)=>{
            setReports(r)
            setLoading(false)
        })
    }

    const reason = [
        {
            value: 1,
            label: 'محتوا یا قیمت گذاری اشتباه می باشد',
        },
        {
            value: 2,
            label: 'محتوا یا تصویر آگهی مشکل دارد',
        },
        {
            value: 3,
            label: 'فروشنده تقلبی یا کلاهبردار می باشد',
        },{
            value: 4,
            label: 'غیره',
        }
    ];

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            render: (type)=> <Tag>{reason.find((x)=> x.id = type).label}</Tag>
        },
        {
            title: 'توضیح',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'آگهی',
            dataIndex: 'ads',
            key: 'ads',
            render: (ads) => <Link target={"_blank"} to={`${Domain}/view/${ads.id}/${Scripts.urlDasher(ads.title)}`}>{ads.title}</Link>
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'ip',
            dataIndex: 'ip',
            key: 'ip'
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment element={'time'} locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at) => <Moment element={'time'} locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
        }
    ]

    return (
        <AdminLayout>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h1>گزارش ها</h1>
                <Button type="primary" onClick={()=>setModel(true)}>جدید</Button>
            </div>
            <Table
                dataSource={reports?.data}
                columns={columns}
                loading={loading}
            />
        </AdminLayout>
    )
}

export default Reports;