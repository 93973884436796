import React, {useState, useEffect, useRef} from "react";
import { TileLayer, MapContainer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

export default function Map(props){

    const [position, setPosition] = useState({ lat: 35.699739, lng: 51.338097 });
    const markerRef = useRef(null);

    useEffect(() => {
        if(props.latlng){
            setPosition({lat: props.latlng[0], lng: props.latlng[1]})
        }
    },[props.latlng])

    const Recenter = ({lat,lng}) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }

    const mapIcon = L.icon({
        iconUrl: 'marker.svg',
    });


    return (
        <>
            <MapContainer center={position} zoom={15} scrollWheelZoom={true} style={{height: "300px", width: "100%"}}>
                <TileLayer
                    attribution='&copy; <a href="https://nobazaar.ir">nobazaar.ir</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker ref={markerRef} position={position} icon={mapIcon} draggable={false}/>
                <Recenter lat={position.lat} lng={position.lng} />
            </MapContainer>
        </>
    )
}