import AdminLayout from "../../layout/admin";
import {deleteBlogPostApi, getBlogApi, getBlogCategoriesApi, postBlogPostApi} from "../../api/api";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Input, message, Modal, Select, Table} from "antd";
import Moment from "react-moment";
import 'moment/locale/fa';
import {useNavigate} from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {editorConfiguration} from "../../api/common";

const Blog = () => {

    const navigate = useNavigate();
    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModel] = useState(false);
    const [newPost, setNewPost] = useState({});

    useEffect(()=>{
        getBlog(1)
        getBlogCategories(null)
    },[])

    const getBlog = async (p) => {
        setLoading(true)
        const data = {
            page: p
        }
        await getBlogApi(data, token).then((r)=>{
            setPosts(r.data)
            setLoading(false)
        })
    }

    const getBlogCategories = async (p) => {
        const data = {
            ...p && {page: p}
        }
        await getBlogCategoriesApi(data, token).then((r)=>{
            r?.data?.data?.filter((x)=> (x.label = x.title, x.value = x.id))
            setCategories(r.data.data)
        })
    }

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'دسته بندی',
            dataIndex: 'category',
            key: 'category',
            render: (category) => category.title
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment element={'time'} locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at) => <Moment element={'time'} locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_,_value) => <>
                <Button type="primary" onClick={()=>navigate(`/blog/edit/${_value.id}`)}>ویرایش</Button>
                <Button style={{marginRight: "10px"}} danger onClick={()=> handleRemove(_value.id)}>حذف</Button>
            </>
        }
    ]

    const handleNewPost = async () => {
        let clone = {...newPost};
        clone.description = JSON.stringify(clone.description)
        await postBlogPostApi(clone, token).then(()=>{
            message.success("با موفقیت ایجاد شد")
            setModel(false)
            getBlog(1)
        })
    }

    const handleRemove = async (id) => {
        await deleteBlogPostApi(id, token).then(()=>{
            message.success("با موفقیت حذف شد")
            getBlog(1)
        })
    }

    return (
        <AdminLayout>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h1>بلاگ</h1>
                <Button type="primary" onClick={()=>setModel(true)}>جدید</Button>
            </div>
            <Table
                dataSource={posts?.data}
                columns={columns}
                loading={loading}
            />
            <Modal open={modal} onCancel={()=>setModel(false)} onOk={()=>handleNewPost()}>
                <div style={{padding: "5px"}}>
                    <Input placeholder={"عنوان"} value={newPost?.title} onChange={(e)=>setNewPost({...newPost, title: e.target.value})}/>
                </div>
                <div style={{padding: "5px"}}>
                    <Select
                        placeholder={"دسته بندی"}
                        style={{width: "100%"}}
                        options={categories}
                        onChange={(e)=>setNewPost({...newPost, category_id: e})}
                    />
                </div>
                <div style={{padding: "5px"}}>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={newPost?.description}
                        config={editorConfiguration}
                        onChange={ ( event, editor ) => {
                            setNewPost({...newPost, description: editor.getData()})
                        }}
                    />
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default Blog;