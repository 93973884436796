import AdminLayout from "../../layout/admin";
import {deleteTicketApi, getTicketsApi, putTicketApi} from "../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Dropdown, message, notification, Table, Tag} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import Moment from "react-moment";
import 'moment/locale/fa';
import {Domain} from "../../api/config";
import Scripts from "../../api/scripts";
import Helper from "../../api/helper";
import {DeleteOutlined} from "@ant-design/icons";

const Tickets = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(false);

    useEffect(()=>{
        getTickets(1)
    },[])

    const getTickets = async (p) => {
        setLoading(true)
        const data = {
            page: p ? p : 1,
        }
        await getTicketsApi(data, token).then((r)=>{
            setTickets(r.data)
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'شماره تیکت',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <span>{id}</span>
        },
        {
            title: 'موضوع',
            dataIndex: 'subject',
            key: 'subject'
        },
        {
            title: 'مرتبط به آگهی',
            dataIndex: 'ads',
            key: 'ads',
            render: (ads) => ads?.id ?
                <>
                    <Link target={"_blank"} to={`${Domain}/view/${ads?.id}/${Scripts.urlDasher(ads.title)}`}>{ads?.title}</Link>
                    <Tag style={{marginRight: "10px"}}>{ads.id}</Tag>
                </>
                : "-"
        },
        {
            title: 'نام و نام خانوادگی',
            dataIndex: 'seller',
            key: 'seller',
            render: (seller)=> <>
                <Link target={"_blank"} to={`${Domain}/seller/${seller.id}`}>{`${seller.name} ${seller.lastname}`}</Link>
                <Tag style={{marginRight: "10px"}}>{seller.id}</Tag>
            </>
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            render: (status) => Helper.TicketStatus(status)
        },
        {
            title: 'تاریخ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at, _value) =>
                <>
                    <Moment locale={'fa'} fromNow>{created_at}</Moment>
                    <br/>
                    <Moment locale={'fa'} fromNow>{_value.updated_at}</Moment>
                </>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (v, _value)=> <Dropdown.Button
                disabled={buttonLoad === _value.id}
                loading={buttonLoad === _value.id}
                menu={{items, onClick: ({key})=> handleTicketStatus(key, _value.id)}}
                onClick={(e) => navigate(`/ticket/${_value.id}`)}
            >
                نمایش
            </Dropdown.Button>
        }
    ];

    const items = [
        {
            label: 'بستن تیکت',
            key: '0',
            value: 0,
        },
        {
            label: 'در انتظار بررسی',
            key: '1',
            value: 1
        },
        {
            label: 'پاسخ پشتیبانی',
            key: '2',
            value: 2
        },
        {
            label: 'پاسخ کاربر',
            key: '3',
            value: 3
        },
        {
            label: 'بی نتیجه',
            key: '4',
            value: 4
        },
        {
            label: 'حل شده',
            key: '5',
            value: 5
        },
        {
            type: 'divider',
        },
        {
            label: 'حذف تکیت',
            key: '666',
            value: 666,
            icon: <DeleteOutlined />,
            danger: true
        }
    ];

    const handlePage = (p) => {
        getTickets(p);
    }

    const updateTicketStatus = async (status, id) => {

        const data = {
            status: status
        }
        await putTicketApi(data, id, token).then(()=> {
            message.success("با موفقیت انجام شد")
            getTickets(1)
        })
    }

    const deleteTicket = async (id) => {
        await deleteTicketApi(id, token).then(()=>{
            message.success("با موفقیت حذف شد")
            getTickets(1)
        })
    }

    const handleTicketStatus = (status, id) => {
        if(status == 666){
            deleteTicket(id)
            return false
        }
        updateTicketStatus(status, id)
    }


    return (
        <AdminLayout>
            <h1>تیکت ها</h1>
            <Table
                loading={loading}
                columns={columns}
                dataSource={tickets?.data}
                pagination={{
                    current: tickets.current_page,
                    total: tickets.total,
                    onChange: (p) => handlePage(p),
                }}
            />
        </AdminLayout>
    )
}

export default Tickets;