import AdminLayout from "../../layout/admin";
import {deleteTagApi, getTagByIdApi, getTagsApi, postTagApi, updateTagApi} from "../../api/api";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Input, message, Modal, Table} from "antd";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Domain} from "../../api/config";
import Scripts from "../../api/scripts";

const Tags = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [newTag, setNewTag] = useState({});
    const [mode, setMode] = useState(null);
    const [tagId, setTagId] = useState(null);

    useEffect(()=>{
        getTags(1)
    },[])

    const getTags = async (p) => {
        setLoading(true)
        const data = {
            page: p,
            limit: 10
        }
        await getTagsApi(data, token).then((r)=>{
            setTags(r.data)
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title',
            render: (title) => <Link target={"_blank"} to={`${Domain}/tag/${Scripts.urlDasher(title)}`}>{title}</Link>
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment element={'time'} locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at) => <Moment element={'time'} locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_,_value)=> <>
                <Button type="primary" onClick={()=>handleEdit(_value.id)}>ویرایش</Button>
                <Button style={{marginRight: "10px"}} danger onClick={()=> handleRemove(_value.id)}>حذف</Button>
            </>
        }
    ]

    const handleEdit = (id) => {
        setModal(true)
        setMode("edit")
        setTagId(id)

        getTagById(id);
    }

    const handleRemove = async (id) => {
        await deleteTagApi(id, token).then(()=>{
            message.success("با موفقیت حذف شد")
            getTags(1)
        })
    }

    const handlePage = (p) => {
        getTags(p)
    }

    const handleNewTag = async () => {
        await postTagApi(newTag, token).then(()=>{
            message.success("با موفقیت ایجاد شد")
            getTags(1)
            setModal(false)
        }).catch(()=>{
            message.error("خطا")
        })
    }

    const getTagById = async (id) => {
        await getTagByIdApi(id, token).then((r)=>{
            setNewTag({title: r.data.title})
        })
    }

    const handleUpdateTag = async () => {
        await updateTagApi(newTag, tagId, token).then(()=>{
            message.success("با موفقیت انجام شد")
            getTags(1)
            setModal(false)
        })
    }

    return (
        <AdminLayout>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h1>برچسب ها</h1>
                <Button type="primary" onClick={()=>(setModal(true), setMode("new"))}>جدید</Button>
            </div>
            <Table
                dataSource={tags?.data}
                columns={columns}
                loading={loading}
                pagination={{
                    current: tags.current_page,
                    pageSize: tags.per_page,
                    total: tags.total,
                    onChange: handlePage
                }}
            />
            <Modal
                open={modal}
                onCancel={()=>setModal(false)}
                onOk={()=> mode === "new" ? handleNewTag() : mode === "edit" ? handleUpdateTag() : null}
            >
                <div style={{padding: "5px"}}>
                    <Input value={newTag?.title} placeholder={"عنوان"} onChange={(e)=>setNewTag({title: e.target.value})}/>
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default Tags;