import { Breadcrumb, Layout, Menu } from 'antd';
import {Link} from "react-router-dom";
import { useLocation } from "react-router-dom"
import { DashboardOutlined,
    ContainerOutlined,
    UsergroupAddOutlined,
    DatabaseOutlined,
    CompassOutlined,
    SafetyOutlined,
    FileOutlined,
    TagOutlined,
    NotificationOutlined,
    SolutionOutlined,
    FolderOutlined
} from '@ant-design/icons';
const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({children}) => {

    const breadcrumb = [];
    const location = useLocation()
    const {pathname} = location;

    const sideBarMenu = [
        {key: 0, label: <Link to={"/"}>پیشخوان</Link>, value: "/", icon: <DashboardOutlined style={{fontSize: "20px"}} />},
        {key: 1, label: <Link to={"/ads"}>آگهی ها</Link>, value: "/ads", icon: <ContainerOutlined style={{fontSize: "20px"}} />},
        {key: 2, label: <Link to={"/members"}>کاربران</Link>, value: "/members", icon: <UsergroupAddOutlined style={{fontSize: "20px"}} />},
        {key: 3, label: <Link to={"/categories"}>دسته بندی ها</Link>, value: "/categories", icon: <DatabaseOutlined style={{fontSize: "20px"}} />},
        {key: 4, label: <Link to={"/cities"}>شهر ها</Link>, value: "/cities", icon: <CompassOutlined style={{fontSize: "20px"}} />},
        {key: 5, label: <Link to={"/tickets"}>تیکت ها</Link>, value: "/tickets", icon: <SafetyOutlined style={{fontSize: "20px"}} />},
        {key: 6, label: <Link to={"/blog"}>بلاگ</Link>, value: "/blog", icon: <FileOutlined style={{fontSize: "20px"}} />,
            children: [
                {key: 7, label: <Link to={"/blog/categories"}>دسته بندی</Link>, value: "/blog/categories", icon: <FolderOutlined style={{fontSize: "20px"}} />}
                ]
        },
        {
            key: 8, label: <Link to={"/tags"}>برچسب ها</Link>, value: "/tags", icon: <TagOutlined style={{fontSize: "20px"}} />
        },
        {
            key: 9, label: <Link to={"/reports"}>گزارش ها</Link>, value: "/reports", icon: <NotificationOutlined style={{fontSize: "20px"}} />
        },
        {
            key: 10, label: <Link to={"/contents"}>محتوا ها</Link>, value: "/contents", icon: <SolutionOutlined style={{fontSize: "20px"}} />
        }
    ]

    const activeMenu = sideBarMenu.find((x)=>x.value == pathname)?.key;

    return (
        <Layout>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img width={65} src={'/logo_white.svg'}/>
            </Header>

            <Layout
                style={{
                    padding: '0 15px 0 0',
                }}
            >
                <Breadcrumb
                    style={{
                        margin: '15px 0',
                    }}
                    items={breadcrumb}
                />
                <Sider
                    width={200}
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[activeMenu?.toString()]}
                        defaultOpenKeys={['sub1']}
                        style={{
                            height: '100%',
                            borderRight: 0,
                            paddingTop: "50px"
                        }}
                        items={sideBarMenu}
                    />
                </Sider>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
    </Layout>
    )
}

export default AdminLayout;