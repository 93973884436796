import {Tag} from "antd";

const Helper = {
    TicketStatus(s){
        let status = null;

        if(s === 1){
            status = <Tag color="processing">در انتظار بررسی</Tag>
        } else if (s === 2){
            status = <Tag color="warning">پاسخ پشتیبانی</Tag>
        } else if (s === 3) {
            status = <Tag color="processing">پاسخ کاربر</Tag>
        } else if (s === 4) {
            status = <Tag color="error">بی نتیجه</Tag>
        } else if (s === 5) {
            status = <Tag color="success">حل شده</Tag>
        } else if (s === 0) {
            status = <Tag color="default">بسته شده</Tag>
        }
        return status;
    }
}

export default Helper;