import {
    SET_TOKEN,
    SET_USER_DATA
} from "../constants/user";

export const setUserData = x => ({
    type: SET_USER_DATA,
    payload: x
});

export const setToken = x => ({
    type: SET_TOKEN,
    payload: x
});