const Scripts = {
    urlDasher (str){
        return str ? str.replace(/\s+/g,'-') : null;
    },
    unDasher (str){
        return str ? decodeURIComponent(str).replace(/-/g,' ') : null;
    },
    formatPrice (p){
        return p?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export default Scripts;