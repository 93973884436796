export const editorConfiguration = {
    language: {
        content: 'fa'
    },
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'alignment',
            'outdent',
            'indent',
            '|',
            'link',
            '|',
            'undo',
            'redo',
            '|',
            'image'
        ]
    }
};