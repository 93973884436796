import {getAdsApi, updateAdsApi} from "../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Table, Dropdown, notification, Tag, Select, Row, Col, Button} from 'antd';
import { CheckOutlined,
    DeleteOutlined,
    CloseOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    CrownOutlined
} from '@ant-design/icons';
import AdminLayout from "../../layout/admin";
import Moment from "react-moment";
import 'moment/locale/fa';
import {Link, useNavigate} from "react-router-dom";
import Scripts from "../../api/scripts";
import {Domain} from "../../api/config";

const Ads = () => {

    const navigate = useNavigate();
    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [ads, setAds] = useState([]);
    const [buttonLoad, setButtonLoad] = useState(null);
    const [tableLoad, setTableLoad] = useState(false);
    const [filter, setFilter] = useState({});
    const [update, setUpdate] = useState({});
    const [adsId, setAdsId] = useState(null);

    useEffect(()=>{
        getAds();
        document.title = "Admin - Ads"
    },[filter]);

    const getAds = async () => {
        setTableLoad(true);
        await getAdsApi(filter, token).then((res)=>{
            res.data.data.filter((x)=>x.key = x.id)
            setAds(res.data)
            setTableLoad(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        if(adsId){
            updateAds()
        }
    },[update]);

    const items = [
        {
            label: 'تائید',
            key: '1',
            value: 1,
            icon: <CheckOutlined />
        },
        {
            label: 'عدم تائید',
            key: '2',
            value: 2,
            icon: <CloseOutlined />
        },
        {
            label: 'حذف',
            key: '3',
            value: 3,
            icon: <DeleteOutlined />,
            danger: true,
        }
    ];

    const columns = [
        {
            title: 'وضعیت',
            dataIndex: 'approved',
            key: 'approved',
            render: (value, _value) =>
                <>
                    {_value.special ? <Tag icon={<CrownOutlined />} color="warning"/> : null}
                    <Tag
                icon={value === 1 ? <CheckCircleOutlined  /> :
                    value === 0 ? <SyncOutlined spin /> :
                        value === 3 ? <ExclamationCircleOutlined /> :
                    null}
                color={value === 1 ? "success" : value === 0 ? "processing" : value === 3 ? "error" : null}/>
                {_value.deleted ? <Tag color="error" icon={<CloseCircleOutlined />}/> : null}
                </>

        },
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id',
            render: (id,_value) =>  <>
                <Link to={`/ads/edit/${_value.id}`}>{id}</Link>
                <br/>
                <Tag>{_value.adsid}</Tag>
            </>
        },
        {
            title: 'بازدید',
            dataIndex: 'view',
            key: 'view'
        },
        {
            title: 'شناسه کاربر',
            dataIndex: 'user',
            key: 'user'
        },
        {
            title: 'تلفن کاربر',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'دسته بندی',
            dataIndex: 'category',
            key: 'category',
            render: (v)=> <Tag>{v?.name}</Tag>
        },
        {
            title: 'استان',
            dataIndex: 'province',
            key: 'province',
            render: (v)=> <Tag>{v?.title}</Tag>
        },
        {
            title: 'تاریخ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value, _value) => <>
                <Moment element={'time'} locale={'fa'} fromNow>{value}</Moment>
                <br/>
                <Moment element={'time'} locale={'fa'} fromNow>{_value.updated_at}</Moment>
            </>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (v, _value)=> <Dropdown.Button
                disabled={buttonLoad === _value.id}
                loading={buttonLoad === _value.id}
                menu={{items, onClick: ({key})=> handleChangeStatus(key, _value.id)}}
                onClick={(e) => navigate(`/ads/edit/${_value.id}`)}
            >
                ویرایش
            </Dropdown.Button>
        }
     ]

    const handleChangeStatus = (key, id) => {
        setButtonLoad(id)
        setButtonLoad(null);
        setAdsId(id);

        if(key == 1){
            setUpdate({approved: 1})
        } else if (key == 2) {
            setUpdate({approved: 3})
        } else if (key == 3) {
            setUpdate({deleted: 1})
        }
    }

    const updateAds = async () => {
        await updateAdsApi(update, adsId, token).then(()=>{
            notification.success({
                message: "موفق",
                description: "عملیات با موفقیت انجام شد"

            })
            getAds();
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <AdminLayout>
            <div>
                <Row>
                    <Col span={12}>
                        <h1>آگهی ها</h1>
                    </Col>
                    <Col span={12}>
                       <Row>
                           <Col span={8}>
                               <Select
                                   placeholder="وضعیت آگهی"
                                   onChange={(v)=>setFilter({...filter, status: v})}
                                   options={[
                                       {
                                           value: 0,
                                           label: 'در انتظار تائید',
                                       },
                                       {
                                           value: 1,
                                           label: 'تائید شده',
                                       },
                                       {
                                           value: 2,
                                           label: 'ویرایش شده',
                                       },
                                       {
                                           value: 3,
                                           label: 'تائید نشده',
                                       },
                                   ]}
                                   style={{width: "100%", padding: "5px"}}
                               />
                           </Col>
                           <Col span={8}>
                               <Select
                                   placeholder="نوع آگهی"
                                   onChange={(v)=>setFilter({...filter, special: v})}
                                   options={[
                                       {
                                           value: 0,
                                           label: 'عادی',
                                       },
                                       {
                                           value: 1,
                                           label: 'ویژه',
                                       }
                                   ]}
                                   style={{width: "100%", padding: "5px"}}
                               />
                           </Col>
                           <Col span={8}>
                               <Select
                                   placeholder="وضعیت حذف"
                                   onChange={(v)=>setFilter({...filter, deleted: v})}
                                   options={[
                                       {
                                           value: 0,
                                           label: 'حذف نشده',
                                       },
                                       {
                                           value: 1,
                                           label: 'حذف شده',
                                       }
                                   ]}
                                   style={{width: "100%", padding: "5px"}}
                               />
                           </Col>
                           <Col>
                               <Button style={{margin: "5px"}} disabled={!Object.entries(filter).length ? true : false} onClick={()=>setFilter({})}>حذف فیلتر ها</Button>
                           </Col>
                       </Row>
                    </Col>
                </Row>
            </div>
            <br/>
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={ads.data}
                    loading={tableLoad}
                    pagination={{
                        current: ads.current_page,
                        pageSize: ads.per_page,
                        total: ads.total,
                        onChange: (v)=> setFilter({...filter, page: v}),
                    }}
                />
            </div>
        </AdminLayout>
    )
}

export default Ads;