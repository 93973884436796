import React from 'react';
import AdminLayout from "../../layout/admin";
import {getContentApi, updateContentApi} from "../../api/api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Input, message} from "antd";
import {useSelector} from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {editorConfiguration} from "../../api/common";

const Content = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const { content_id } = useParams();
    const [content, setContent] = useState({});
    const [editor, setEditor] = useState(false);

    useEffect(()=>{
        if(content_id){
            getContent()
        }
    },[])

    const getContent = async () => {
        await getContentApi(content_id, token).then((r)=>{
            r.data.description = JSON.parse(r.data.description);
            setContent(r.data)
            setEditor(true)
        })
    }

    const handleUpdateContent = async () => {
        let clone = {...content};
        clone.description = JSON.stringify(clone.description);
        await updateContentApi(clone, content_id, token).then(()=>{
            message.success("با موفقیت بروز شد")
        })
    }

    return (
        <AdminLayout>
            <h1>ویرایش محتوا</h1>
            <div style={{padding: "5px"}}>
                <Input value={content?.title} onChange={(e)=>setContent({...content, title: e.target.value})}/>
            </div>
            <div style={{padding: "5px"}}>
                <Input value={content?.slug} onChange={(e)=>setContent({...content, slug: e.target.value})}/>
            </div>
            <div style={{padding: "5px"}}>
                {editor ?
                    <CKEditor
                        editor={ ClassicEditor }
                        data={content?.description}
                        config={editorConfiguration}
                        onChange={ ( event, editor ) => {
                            setContent({...content, description: editor.getData()})
                        }}
                    /> : null}
            </div>
            <div style={{marginTop: "15px"}}>
                <Button type="primary" onClick={()=>handleUpdateContent()}>بروزرسانی</Button>
            </div>
        </AdminLayout>
    )
}

export default Content;