import AdminLayout from "../../layout/admin";
import {Button, Input, message, Modal, Table} from "antd";
import Moment from "react-moment";
import {deleteBlogCategoryApi, getBlogCategoriesApi, postBlogCategoryApi} from "../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const BlogCategories = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({});

    useEffect(()=>{
        getBlogCategories(1)
    },[])

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment element={'time'} locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at) => <Moment element={'time'} locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_,_value)=> <>
                <Button type="primary" onClick={()=>handleEdit()}>ویرایش</Button>
                <Button style={{marginRight: "10px"}} danger onClick={()=>handleRemove(_value.id)}>حذف</Button>
            </>
        }
    ]

    const getBlogCategories = async (p) => {
        setLoading(true)
        const data = {
            page: p
        }
        await getBlogCategoriesApi(data, token).then((r)=>{
            setCategories(r.data)
            setLoading(false)
        })
    }

    const handleEdit = () => {
        setModal(true)
    }

    const handleNew = async () => {
        await postBlogCategoryApi(value, token).then(()=>{
            message.success("با موفقیت ایجاد شد")
            getBlogCategories(1)
            setModal(false)
        })
    }

    const handleRemove = async (id) => {
        await deleteBlogCategoryApi(id, token).then(()=>{
            message.success("با موفقیت حذف شد")
            getBlogCategories(1)
        })
    }

    return (
        <AdminLayout>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h1>دسته بندی های بلاگ</h1>
                <Button type="primary" onClick={()=>setModal(true)}>جدید</Button>
            </div>
            <Table
                dataSource={categories?.data}
                columns={columns}
                loading={loading}
            />
            <Modal
                open={modal}
                onCancel={()=>setModal(false)}
                onOk={()=>handleNew()}
            >
                <div style={{padding: "5px"}}>
                    <Input placeholder={"عنوان"} value={value?.title} onChange={(e)=>setValue({...value, title: e.target.value})}/>
                </div>
                <div style={{padding: "5px"}}>
                    <Input.TextArea placeholder={"توضیحات"} value={value?.description} onChange={(e)=>setValue({...value, description: e.target.value})}/>
                </div>
            </Modal>
        </AdminLayout>
    )
}

export default BlogCategories;