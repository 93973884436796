import {post, put, get, remove} from './axios';
import{BaseApiUrl} from "./config";
import {
    get_ads,
    update_ads,
    get_ads_id,
    user_login,
    user_confirm,
    get_sellers,
    get_category,
    get_sub_category,
    get_brands,
    get_models,
    get_subcat_sub,
    get_province,
    get_district_by_city,
    post_category,
    post_sub_category,
    get_city_by_province,
    post_sub_sub_category,
    send_seller_sms,
    get_tickets,
    update_ticket,
    delete_ticket,
    get_blog,
    get_blog_post,
    put_blog_post,
    post_brand,
    post_model,
    get_ticket_by_id,
    get_blog_categories,
    get_tags,
    delete_tag,
    get_contents,
    post_content,
    get_content_by_id,
    put_content,
    dashboard_stats,
    post_blog_category,
    post_blog,
    delete_content,
    delete_blog_post,
    delete_blog_category,
    post_tag,
    get_tag_id,
    update_tag,
    get_reports,
    post_message,
    create_ticket_message,
    get_ticket_messages, put_ticket_message
} from "./routes";

export const postSellerLoginApi = (data) => {
    let url = user_login;
    return post(BaseApiUrl + url, data);
}

export const postSellerConfirmApi = (data) => {
    let url = user_confirm;
    return post(BaseApiUrl + url, data);
}

export const getAdsApi = (data, token) => {
    let url = get_ads;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getAdsIdApi = (id, token) => {
    let url = get_ads_id(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const updateAdsApi = (data, id, token) => {
    let url = update_ads(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getSellerApi = (data, token) => {
    let url = get_sellers;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postSellerSms = (data, token) => {
    let url = send_seller_sms;
    if (data) { url += `/${data.code}/${data.tel}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getCategoryApi = () => {
    let url = get_category;
    return get(BaseApiUrl + url);
}

export const getSubCategoryApi = (id) => {
    let url = get_sub_category(id);
    return get(BaseApiUrl + url);
}

export const getSubSubCategoryApi = (id) => {
    let url = get_subcat_sub(id);
    return get(BaseApiUrl + url);
}

export const getBrandsApi = (id) => {
    let url = get_brands(id);
    return get(BaseApiUrl + url);
}

export const getModelsApi = (id) => {
    let url = get_models(id);
    return get(BaseApiUrl + url);
}

export const getProvinceApi = () => {
    let url = get_province;
    return get(BaseApiUrl + url);
}

export const getCityByProvinceApi = (id) => {
    let url = get_city_by_province(id);
    return get(BaseApiUrl + url);
}

export const getDistrictByCityApi = (id) => {
    let url = get_district_by_city(id);
    return get(BaseApiUrl + url);
}

export const postCategoryApi = (data, token) => {
    let url = post_category;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postSubCategoryApi = (data, token) => {
    let url = post_sub_category;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postSubSubCategoryApi = (data, token) => {
    let url = post_sub_sub_category;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postBrandApi = (data, token) => {
    let url = post_brand;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postModelApi = (data, token) => {
    let url = post_model;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getTicketsApi = (data, token) => {
    let url = get_tickets;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getTicketByIdApi = (id, token) => {
    let url = get_ticket_by_id(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const putTicketApi = (data, id, token) => {
    let url = update_ticket;
    return put(BaseApiUrl + url + `/${id}`, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const deleteTicketApi = (id, token) => {
    let url = delete_ticket(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const createTicketMessageApi = (data, token) => {
    let url = create_ticket_message;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getTicketMessageApi = (id, token) => {
    let url = get_ticket_messages(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const putTicketMessageApi = (id, data, token) => {
    let url = put_ticket_message(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getBlogApi = (data, token) => {
    let url = get_blog;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getBlogPostApi = (id, token) => {
    let url = get_blog_post(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postBlogPostApi = (data, token) => {
    let url = post_blog;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const deleteBlogPostApi = (id, token) => {
    let url = delete_blog_post(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const deleteBlogCategoryApi = (id, token) => {
    let url = delete_blog_category(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getBlogCategoriesApi = (data, token) => {
    let url = get_blog_categories;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const updateBlogPostApi = (data, id, token) => {
    let url = put_blog_post(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postBlogCategoryApi = (data, token) => {
    let url = post_blog_category;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postTagApi = (data, token) => {
    let url = post_tag;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const updateTagApi = (data, id, token) => {
    let url = update_tag(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getTagsApi = (data, token) => {
    let url = get_tags;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getTagByIdApi = (id, token) => {
    let url = get_tag_id(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const deleteTagApi = (id, token) => {
    let url = delete_tag(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getContentsApi = (data, token) => {
    let url = get_contents;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postContentApi = (data, token) => {
    let url = post_content;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const deleteContentApi = (id, token) => {
    let url = delete_content(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getContentApi = (id, token) => {
    let url = get_content_by_id(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const updateContentApi = (data, id, token) => {
    let url = put_content(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getDashboardStats = (token) => {
    let url = dashboard_stats;
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getReportsApi = (data, token) => {
    let url = get_reports;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postMessageApi = (data, token) => {
    let url = post_message;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}