import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Provider, useSelector} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";

import Ads from './pages/ads';
import Members from './pages/members';
import EditAds from './pages/ads/[ads_id]';
import Login from './pages/login';
import Categories from './pages/categories';
import Tickets from './pages/tickets';
import Ticket from './pages/tickets/[ticket_id]';
import Blog from './pages/blog';
import BlogPost from './pages/blog/[post_id]';
import BlogCategories from './pages/blog/categories';
import Tags from './pages/tags';
import Contents from './pages/contents';
import Content from './pages/contents/[content_id]';
import Dashboard from "./pages/dashboard";
import Reports from "./pages/reports";

function App({pageProps}) {
  const isAuthenticated = useSelector(state => state.auth.token);

  return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes {...pageProps}>
            <Route index element={isAuthenticated ? <Dashboard/> : <Navigate to="/login" /> }/>
            <Route path={"ads"} element={isAuthenticated ? <Ads/> : <Navigate to="/login" /> }/>
            <Route path={"ads/edit/:ads_id"} element={isAuthenticated ? <EditAds/> : <Navigate to="/login" /> } />
            <Route path={"members"} element={isAuthenticated ? <Members/> : <Navigate to="/login" /> } />
            <Route path={"categories"} element={isAuthenticated ? <Categories/> : <Navigate to="/login" /> } />
            <Route path={"tickets"} element={isAuthenticated ? <Tickets/> : <Navigate to="/login" /> } />
            <Route path={"ticket/:ticket_id"} element={isAuthenticated ? <Ticket/> : <Navigate to="/login" /> } />
            <Route path={"blog"} element={isAuthenticated ? <Blog/> : <Navigate to="/login" /> } />
            <Route path={"blog/edit/:post_id"} element={isAuthenticated ? <BlogPost/> : <Navigate to="/login" /> } />
            <Route path={"blog/categories"} element={isAuthenticated ? <BlogCategories/> : <Navigate to="/login" /> } />
            <Route path={"tags"} element={isAuthenticated ? <Tags/> : <Navigate to="/login" /> } />
            <Route path={"contents"} element={isAuthenticated ? <Contents/> : <Navigate to="/login" /> } />
            <Route path={"content/edit/:content_id"} element={isAuthenticated ? <Content/> : <Navigate to="/login" /> } />
            <Route path={"reports"} element={isAuthenticated ? <Reports/> : <Navigate to="/login" /> } />
            <Route path={"login"} element={<Login/>} />
          </Routes>
        </PersistGate>
      </Provider>
  );
}

export default App;
