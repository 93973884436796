export const get_sellers = "admin/sellers";
export const send_seller_sms = "admin/seller/sms";

export const get_ads = "admin/ads";
export const get_ads_id = (id) => `admin/ads/${id}`;
export const update_ads = (id) =>  `admin/ads/update/${id}`;

export const user_login = "seller/login";
export const user_confirm = "seller/confirm";

export const get_category = "category/index";
export const get_sub_category = (id) => `category/sub/${id}`;
export const get_subcat_sub = (id) => `subcat/sub/${id}`;
export const get_brands = (id) => `subcat/brand/${id}`;
export const get_models = (id) => `brand/model/${id}`;
export const get_province = "province/index";
export const get_city_by_province = (id) => `city/province/${id}`;
export const get_district_by_city = (id) => `district/city/${id}`;
export const post_category = "category/create";
export const post_sub_category = "subcat/create";
export const post_sub_sub_category = "sscat/create";
export const post_brand = "brand/create";
export const post_model = "model/create";

export const get_tickets = "admin/ticket/index";
export const get_ticket_by_id = (id) => `admin/ticket/view/${id}`;
export const update_ticket = "admin/ticket/update";
export const delete_ticket = (id) => `admin/ticket/delete/${id}`;
export const create_ticket_message = "ticket_message/create"
export const get_ticket_messages = (id) => `ticket_message/${id}`;
export const put_ticket_message = (id) => `admin/ticket/update/${id}`;

export const get_blog = "blog/posts";
export const post_blog = "blog/create";
export const get_blog_post = (id) => `blog/show/${id}`;
export const put_blog_post = (id) => `blog/update/${id}`;
export const delete_blog_post = (id) => `blog/delete/${id}`;
export const get_blog_categories = "blog/categories";
export const delete_blog_category = (id) => `blog/category/delete/${id}`;
export const post_blog_category = "blog/category/create";
export const get_tags = "tags/index";
export const post_tag = "tags/create";
export const get_tag_id = (id) => `tags/show/${id}`;
export const update_tag = (id) => `tags/update/${id}`;
export const delete_tag = (id) => `tags/delete/${id}`;
export const get_contents = "contents/index";
export const post_content = "content/create";
export const get_content_by_id = (id) => `content/edit/${id}`;
export const put_content = (id) => `content/update/${id}`;
export const delete_content = (id) => `content/delete/${id}`;
export const dashboard_stats = "dashboard/stats";
export const get_reports = "reports/index";
export const post_message = "message/create";