import AdminLayout from "../../layout/admin";
import { Statistic, Card, Row, Col } from 'antd';
import CountUp from 'react-countup';
import {getDashboardStats} from "../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { UsergroupAddOutlined, ContainerOutlined, WomanOutlined, ManOutlined } from '@ant-design/icons';

const formatter = (value) => <CountUp end={value} separator="," />;

const Dashboard = () => {

    useEffect(()=>{
        getStats();
        document.title = "Admin - Dashboard"
    },[])

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [stats, setStats] = useState();

    const getStats = async () => {
        await getDashboardStats(token).then((r)=>{
            setStats(r.data)
        }).catch(()=>{

        })
    }

    return (
        <AdminLayout>
            <h1>داشبورد</h1>
            <Row gutter={20}>
                <Col span={12}>
                    <Card>
                        <Statistic prefix={<UsergroupAddOutlined />} title="تعداد کاربران" value={stats?.members?.all} formatter={formatter} />
                        <Statistic prefix={<ManOutlined />} value={stats?.members?.men} formatter={formatter} />
                        <Statistic prefix={<WomanOutlined />} value={stats?.members?.women} formatter={formatter} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic prefix={<ContainerOutlined />} title="تعداد آگهی ها" value={stats?.ads?.all} formatter={formatter} />
                    </Card>
                </Col>
            </Row>
        </AdminLayout>
    )
}

export default Dashboard