import {
    SET_TOKEN,
    SET_USER_DATA
} from '../constants/user';

const initState = {
    token: null,
    user: null
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
}

export default auth