import {Button, Input} from 'antd';
import {useState} from "react";
import {postSellerConfirmApi, postSellerLoginApi} from "../../api/api";
import {setToken, setUserData} from "../../redux/actions/user";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState(null);
    const [confirm, setConfirm] = useState(false);

    const postSellerLogin = async () => {
        await postSellerLoginApi({
            tel: phone
        }).then(()=>{
            setConfirm(true)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const postSellerConfirm = async () => {
        await postSellerConfirmApi({
            tel: phone,
            code: code
        }).then((res)=>{
            dispatch(setToken(res.data.token))
            dispatch(setUserData(res.data.user))
            navigate("/")
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <div style={{width: "300px", margin: "auto"}}>
            <Input onChange={(e)=>setPhone(e.target.value)}/>
            {confirm ? <Input onChange={(e)=>setCode(e.target.value)}/> : null}
            {!confirm ?
                <Button disabled={!phone} onClick={()=>postSellerLogin()}>Login</Button> :
                <Button disabled={!code} onClick={()=>postSellerConfirm()}>Confirm</Button>}
        </div>
    )
}

export default Login;