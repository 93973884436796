import React from 'react';
import AdminLayout from "../../layout/admin";
import {getBlogPostApi, updateBlogPostApi} from "../../api/api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Input, message, Upload, Image} from "antd";
import {useSelector} from "react-redux";
import { UploadOutlined } from '@ant-design/icons';
import {Root} from "../../api/config";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {editorConfiguration} from "../../api/common";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const BlogPost = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const { post_id } = useParams();
    const [post, setPost] = useState({});
    const [imageUrl, setImageUrl] = useState(null);
    const [editor, setEditor] = useState(false);

    useEffect(()=>{
        if(post_id){
            getBlogPost()
        }
    },[])

    const getBlogPost = async () => {
        await getBlogPostApi(post_id, token).then((r)=>{
            r.data.description = JSON.parse(r.data.description);
            setPost(r.data)
            setEditor(true)
        })
    }

    const handleUpdatePost = async () => {
        let data = {...post}
        if(imageUrl){
            data = {...data, img: imageUrl, image: post_id}
        }
        data.description = JSON.stringify(data.description);

        await updateBlogPostApi(data, post_id, token).then(()=>{
            message.success("با موفقیت بروز شد")
        })
    }

    const customRequest = ({ file, onSuccess }) => {

        const reader = new FileReader();
        reader.onload = (e) => {
            const previewImage = e.target.result;
            setImageUrl(previewImage)
            onSuccess();
        };
        reader.readAsDataURL(file);
    }

    const handleRemove = (file) => {
        const name = file.name;
    }

    return (
        <AdminLayout>
            <h1>ویرایش پست</h1>
            <div style={{padding: "5px"}}>
                <Input value={post.title} placeholder={"عنوان"} onChange={(e)=> setPost({...post, title: e.target.value})}/>
            </div>
            <div style={{padding: "5px"}}>
                {editor ?
                <CKEditor
                    editor={ ClassicEditor }
                    data={post?.description}
                    config={editorConfiguration}
                    onChange={ ( event, editor ) => {
                        setPost({...post, description: editor.getData()})
                    }}
                /> : null}
            </div>
            <div style={{padding: "5px"}}>
                <Image width={200} src={`${Root}/blog/${post_id}/${post?.image}-resized.jpg`} alt={post.title}/>

                <Upload
                    accept={"image/*"}
                    customRequest={customRequest}
                    listType="picture-card"
                    showPreviewIcon={false}
                    showUploadList={false}
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt=""
                            style={{
                                width: '100%',
                            }}
                        />
                    ) : <UploadOutlined />}

                </Upload>
            </div>
            <div style={{marginTop: "15px"}}>
                <Button type="primary" onClick={()=>handleUpdatePost()}>بروزرسانی</Button>
            </div>
        </AdminLayout>
    )
}

export default BlogPost;