import {getSellerApi, postSellerSms} from "../../api/api";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import AdminLayout from "../../layout/admin";
import {Table, Badge, Tag, Dropdown, message, Modal} from "antd";
import {
    EnvironmentOutlined,
    ManOutlined,
    WomanOutlined,
    InfoCircleOutlined,
    UserAddOutlined,
    ShopOutlined,
    CheckOutlined,
    DeleteOutlined,
    IdcardOutlined
} from "@ant-design/icons";
import Moment from "react-moment";
import {Domain} from "../../api/config";
import axios from 'axios';
import Map from "../../components/map";

const Members = () => {

    const navigate = useNavigate();
    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const [members, setMembers] = useState([]);
    const [tableLoad, setTableLoad] = useState(false);
    const [filter, setFilter] = useState({});
    const [buttonLoad, setButtonLoad] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalObj, setModalObj] = useState({});
    const [ipData, setIpData] = useState([]);
    const [columnModal, setColumnModal] = useState([]);

    useEffect(()=>{
        document.title = 'مدیریت نوبازار - کاربران';
    },[])

    useEffect(()=>{
        getSellers(filter?.page ? filter?.page : 1)
    },[filter])

    useEffect(()=>{
        if(modalObj.type === "ip" && modalObj.value.length){
            handleIp()
        }
    },[modalObj])

    const getSellers = async (p) => {
        setTableLoad(true)
        await getSellerApi({page: p}, token).then((res)=>{
            res.data.data.filter((x)=>x.key = x.id)
            setMembers(res.data)
            setTableLoad(false)
        })
    }

    const columns = [
        {
            title: 'آواتار',
            dataIndex: 'pic',
            key: 'pic',
            render: (pic, _value)=>
                <>
                    <img width={30} height={30} src={`${Domain}/assets/img/avatar/${pic ? pic : 0}.png`} />
                    <br/>
                    <span style={{fontSize: "20px"}}>{_value.user_type == 1 ? <UserAddOutlined /> : <ShopOutlined />}</span>
                </>
        },
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id',
            render: (id, _value) => <>
                <span>{id}</span>
                <br/>
                {_value.public ? <Link target={"_blank"} to={`${Domain}/seller/${id}`} style={{fontSize: "20px"}}><IdcardOutlined /></Link> : null}
            </>
        },
        {
            title: 'وضعیت',
            dataIndex: 'confirmed',
            key: 'confirmed',
            render: (confirmed)=> <Badge status={confirmed === 1 ? "success" : confirmed == 0 ? "error" : "error"} />
        },
        {
            title: 'ورود',
            dataIndex: 'logged',
            key: 'logged',
            render: (logged)=> <Badge status={logged === 1 ? "success" : logged == 0 ? "error" : "error"} />
        },
        {
            title: 'نام نام خانوادگی',
            dataIndex: 'name',
            key: 'name',
            render: (_,_value) => <>
                <span>{_value.name} {_value.lastname}</span>
                {_value.meli_code ? <img width={15} height={15} src={`${Domain}/assets/img/verified.svg`}/> : null}
            </>
        },
        {
            title: 'جنسیت',
            dataIndex: 'gender',
            key: 'gender',
            render: (gender) => <span style={{fontSize: "20px"}}>{gender == 1 ? <ManOutlined style={{color: "lightblue"}} /> : <WomanOutlined style={{color: "pink"}} />}</span>
        },
        {
            title: 'تلفن',
            dataIndex: 'tel',
            key: 'tel',
            render: (tel, _value) => <>
                <span>{tel}</span>
                <br/>
                {_value.code ? <Tag>{_value.code}</Tag> : null}
            </>
        },
        {
            title: 'ایمیل',
            dataIndex: 'mail',
            key: 'mail'
        },
        {
            title: 'ip',
            dataIndex: 'ip',
            key: 'ip',
            render: (ip) => <InfoCircleOutlined onClick={()=>(setModal(true), setModalObj({type: "ip", value: ip}))} style={{fontSize: "20px"}} />
        },
        {
            title: 'موقعیت',
            dataIndex: 'latlong',
            key: 'latlong',
            render: (latlong) => (latlong ? <EnvironmentOutlined onClick={()=> (setModal(true), setModalObj({type: "latlong", value: latlong}))} style={{fontSize: "20px"}} title={latlong} /> : null)
        },
        {
            title: 'مسدود',
            dataIndex: 'block',
            key: 'block',
            render: (block)=> <Badge status={block == 1 ? "error" : ""} />
        },
        {
            title: 'تاریخ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value,_value) => <>
                <Moment element={'time'} locale={'fa'} fromNow>{_value.updated_at}</Moment>
                <br/>
                <Moment element={'time'} locale={'fa'} fromNow>{_value.created_at}</Moment>
            </>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (v, _value)=> <Dropdown.Button
                disabled={buttonLoad === _value.id}
                loading={buttonLoad === _value.id}
                menu={{items, onClick: ({key})=> handleSellerAction(key, _value)}}
                onClick={(e) => navigate(`/seller/edit/${_value.id}`)}
            >
                ویرایش
            </Dropdown.Button>
        }
    ]

    const items = [
        {
            label: 'ارسال کد تائید',
            key: '1',
            value: 1,
            icon: <CheckOutlined />
        },
        {
            label: 'مسدود کردن',
            key: '2',
            value: 2,
            icon: <DeleteOutlined />,
            danger: true,
        }
    ];

    const handleSellerAction = (key, seller) => {
        setButtonLoad(seller.id)

        if(key == 1){
            handleSendSms(seller?.code, seller.tel);
        } else if (key == 2) {
            alert("block")
        }
    }

    const handleSendSms = async (code, tel) => {
        const data = {
            code: code,
            tel: tel
        }
        await postSellerSms(data, token).then(()=>{
            setButtonLoad(null)
            message.success("با موفقیت ارسال شد")
        })
    }

    const handleIp = async () => {
        await axios.get(`http://ip-api.com/json/${modalObj?.value}`).then((r)=>{
            const tableData = Object.keys(r.data).map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
            }));
            setColumnModal(tableData)
            setIpData(r.data)
        })
    }

    return (
        <AdminLayout>
            <h1>کاربران</h1>
            <Table
                columns={columns}
                dataSource={members.data}
                loading={tableLoad}
                pagination={{
                    current: members.current_page,
                    pageSize: members.per_page,
                    total: members.total,
                    onChange: (v)=> setFilter({...filter, page: v}),
                }}
            />
            <Modal
                centered open={modal}
                onOk={()=>setModal(false)}
                onCancel={()=>setModal(false)}
                width={800}
            >
                {modalObj.type === "ip" ?
                    <Table
                        scroll={{ x: 400 }}
                        columns={columnModal}
                        dataSource={[ipData]}
                        pagination={false}
                        bordered
                    /> : modalObj.type === "latlong" ?
                        <Map latlng={modalObj.value.split(',')}/>
                        : null}
            </Modal>
        </AdminLayout>
    )
}

export default Members;